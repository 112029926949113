// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import Popper from "popper.js";
import 'slick-carousel'
window.jQuery = $;
window.$ = $;

require("bootstrap");
require("slick-carousel");
require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");

// Remove this demo code, that's only here to show how the .env file works!
// if (process.env["HELLO"]) {
//   console.log(`Hello ${process.env.HELLO}!`);
// }valeeeu
